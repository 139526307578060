.sidebar {
    height: 100vh;
}

.sidebar,
.sidebar-content {
	background: white;
	transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
	box-shadow: 5px 3px 15px 3px rgba(69,69,69,0.29);
}

.global-search {
    box-shadow: 20px 3px 15px 3px rgba(69,69,69,0.29);
}

.sidebar-content {
	display: flex;
	flex-direction: column;
	height: 100vh
}

.nav-item {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.nav-item-icon {
    color: #334D6E;
    
    /* font-size: ; */
}
.nav-link {
    color: #334D6E;
    font-size: 1.35rem;
    font-weight: 500;
    margin-left: 1.1rem;
}

.nav-item:hover > *,
.nav-item.active > * {
    color: #009ef6 !important;
    cursor: pointer;
}

.global-search-input > * {
    border: 0;
    color: #446288;
    /* width: "100%" */
    /* justify-content: center; */
}

.global-search-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.hover-effect:hover {
    transition: all .35s ease-in-out;
    cursor: pointer;
    /* make div pop out */
    transform: scale(1.02);
}