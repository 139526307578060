.cardImageItem {
    position:relative;
    padding-top:20px;
    display:inline-block;
}
.cardImageNotify {
    position: absolute;
    right: -15px;
    top: 5px;
    background: var(--bs-blue);
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    color:white;
    padding:5px 10px;
    font-size: 15px;
}