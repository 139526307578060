.tasks-and-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.show-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dropdown-select-week {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; 
    border: none;
    /* needed for Firefox: */
    overflow: hidden;
    color: var(--bs-primary);
}

/* .progress {
    height: 0.5rem !important;
} */

.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 1em;
    text-align: center;
}
.btn-circle.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 25px;
    font-size: 10px;
    text-align: center;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
}
