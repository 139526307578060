thead {
    border-bottom: 1px solid lightgrey;
}

thead {
    color: #334D6E;
    font-size: 1.15rem;
}

tbody {
    /* color: #334D6E; */
    font-size: 1.25rem;
}

.table td:first-child, .table th:first-child, .table tr:first-child {
    padding-left: 1em;
}

.table td:last-child, .table th:last-child, .table tr:last-child {
    padding-right: 1em;
}

input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(0.75); /* IE */
  -moz-transform: scale(0.75); /* FF */
  -webkit-transform: scale(0.75); /* Safari and Chrome */
  -o-transform: scale(0.75); /* Opera */
  padding: 10px;
}

.list-group-item {
    border: 0px !important;
}

.modal-footer {
    display: block;
}